import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#B20000",
                secondary: "#C74545",
                accent:"#DE9191",
                error: colors.red.accent3,
                ripple: colors.grey.lighten1,
            },
            dark: {
                primary: colors.cyan.lighten3,
            },
        },
    },
    icons: [
        {
            iconfont: "mdi",
        },
    ],
});
