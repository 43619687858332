import Vue from "vue";
import VueRouter from "vue-router";
import Blank from "../views/Blank";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/",
    name: "blank",
    component: Blank,
    children: [
      {
        path: "login",
        name: "blank.login",
        component: () => import("../views/auth/Login.vue")
        // beforeEnter: (to, from, next) => {
        //         //   if (localStorage.getItem("token") != null) next();
        //         //   else {
        //         //     router.push({ name: "blank.login" });
        //         //   }
        //         // }
      },
      {
        path: "forgot-password",
        name: "blank.forgot-password",
        component: () => import("../views/auth/ForgotPassword.vue")
      },
      {
        path: "reset",
        name: "blank.reset",
        component: () => import("../views/auth/Reset.vue")
      }
    ]
  },

  {
    path: "/app",
    name: "master",
    component: () => import("../views/layout/Master.vue"),
    children: [
      {
        path: "dashboard",
        name: "master.dashboard",
        component: () => import("../views/Dashboard")
      },

      {
        path: "reviewed",
        name: "master.reviewed",
        component: () => import("../views/reviewed/Reviewed")
      },

      {
        path: "reviewed-details/:id",
        name: "master.reviewedDetails",
        component: () => import("../views/reviewed/ReviewedDetails")
      },
      {
        path: "pending",
        name: "master.pending",
        component: () => import("../views/Orders/Pending")
      },
      {
        path: "order-details/:id",
        name: "master.orderDetails",
        component: () => import("../views/Orders/PendingDetails")
      },
      {
        path: "cheque-details",
        name: "master.chequeDetails",
        component: () => import("../views/finance/ChequeDetails")
      },
      {
        path: "cars",
        name: "master.cars",
        component: () => import("../views/Cars/Assignments")
      },
      {
        path: "settings",
        name: "master.settings",
        component: () => import("../views/Settings/Settings")
      },
      {
        path: "cheques",
        name: "master.cheques",
        component: () => import("../views/finance/Cheques")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
