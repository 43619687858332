import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSwal from "vue-swal";
import "../config/firebase.config";

import "./assets/css/main.css";
Vue.config.productionTip = false;
Vue.use(VueSwal);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
