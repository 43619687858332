/* eslint-disable no-unused-vars */
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyDB_UxioFPje4KMNbQDUliDlaqSkIxnb54",
    authDomain: "pega-89c4a.firebaseapp.com",
    projectId: "pega-89c4a",
    storageBucket: "pega-89c4a.appspot.com",
    messagingSenderId: "711335633545",
    appId: "1:711335633545:web:5e0e6e7c943ac7ff291c58",
    measurementId: "G-7QJ5J17JTB"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();